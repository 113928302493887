import React from "react";
import { HashLink } from "react-router-hash-link";
import logo from "../images/catech.png";

const Footer = () => {
  return (
    <>
      <footer>
        <div className="footer max-w-full mx-auto px-4 sm:px-6 bg-gray-100 border-t border-b py-30">
          {/* Top area: Blocks */}
          <div className="grid sm:grid-cols-12 gap-5 py-8 md:py-12 border-t border-gray-200 lg:ml-11">
            {/* 1st block */}
            <div className="col-span-12 lg:col-span-4">
              <div className="ml-8 mr-8  box-border border-b-4 border-blue-900 p-8 bg-gray-200 text-gray-600 text-center rounded-lg xl:w-80 mx-auto">
                <img
                  className="m-auto"
                  src={logo}
                  alt="logo"
                  width={220}
                  height={10}
                />

                <div className="m-auto text-md font-medium text-gray-600">
                  <h5>19A, El Khalifa El Mamoun,</h5>
                  <p>St. Heliopolis,Cairo, Egypt</p>
                  <p className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                    <a
                      href="mailto:catech@catech.com.eg"
                      className="text-[#013289] hover:text-gray-900"
                    >
                                          Email:{" "}
                                          catech@catech.com.eg
                    </a>
                  </p>
                  <p className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">
                    Email:{" "}
                    <a
                      href="mailto:sales@catech.com.eg"
                      className="text-[#013289] hover:text-gray-900"
                    >
                      sales@catech.com.eg
                    </a>
                  </p>
                  
                  <p className="text-[#013289] hover:text-gray-900">
                    Tel: +20 2 2419 64 40
                  </p>
                  <p className="text-[#013289] hover:text-gray-900">
                    Tel: +20 2 2418 77 60
                  </p> 
                  <p className="text-[#013289] hover:text-gray-900">
                    Mob: 01288832596
                  </p>

                 <p className="text-[#013289] hover:text-gray-900 ">
                    Mob: 01001590227
                  </p>
                </div>
              </div>
            </div>

            {/* 2nd block */}
            <div className="ml-20 col-span-12  lg:col-span-4  md:col-span-6 mx-auto ">
              <h6 className="text-[#013289] text-xl font-bold mb-4">LINKS</h6>
              <ul className="text-md">
                <li className="mb-2">
                  <HashLink
                    to="#about"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    About
                  </HashLink>
                </li>
                {/* <li className="mb-2">
                            <HashLink to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Services</HashLink>
                        </li> */}
                <li className="mb-2">
                  <HashLink
                    to="/contact#contact"
                    className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out"
                  >
                    Contact
                  </HashLink>
                </li>
              </ul>
            </div>

            {/* 3rd block */}
            {/* <div className="col-span-12 lg:col-span-3 mx-auto text-center">
              <h6 className="text-[#013289] text-xl font-bold mb-4">
                Our Partners
              </h6>
              <ul className="text-md">
                        <li className="mb-2">
                            <Link to="https://www.americandynamics.net" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">AmericanDynamics</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.sensormatic.com/" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Sensormatic</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="https://www.sensormatic.com/" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Sensormatic</Link>
                        </li>
            
                </ul>

            </div> */}
            {/* <div className="col-span-6 md:col-span-6 lg:col-span-4 mx-auto">
                        <h6 className="text-[#013289] text-xl font-bold mb-4">OUR SERVICES</h6>
                        <ul className="text-md">
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Security System</Link>
                        </li>
                        {/* <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Security System</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Security System</Link>
                        </li>
                        <li className="mb-2">
                            <Link to="#" className="text-[#013289] hover:text-gray-900 hover:tracking-wider transition duration-250 ease-in-out">Security System</Link>
                        </li> 
                        </ul>
                    </div> */}

            {/* 4th block */}
            <div className="ml-10  col-span-12 text-center mx-auto  md:col-span-6 lg:col-span-4 font-bold uppercase text-blue-900">
              <div className="text-xl mb-6">Social Media Links.</div>

              <div className="text-md font-medium mb-6">
                Follow us on social media.
              </div>
              <div className="mx-auto text-center mt-2">
                <ul className="flex justify-center mb-4 md:mb-0">
                  <li>
                    <HashLink
                      to="https://www.linkedin.com/company/catech-cairo-technical-service-office/about/"
                      className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="Facebook"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <svg
                          xmlns="http://www.w3.org/2000/svg"
                          width="24"
                          height="24"
                          viewBox="-4 -4  26 30"
                          className="fill-current font-black hover:animate-pulse"
                        >
                          <circle cx="4.983" cy="5.009" r="2.188"></circle>
                          <path d="M9.237 8.855v12.139h3.769v-6.003c0-1.584.298-3.118 2.262-3.118 1.937 0 1.961 1.811 1.961 3.218v5.904H21v-6.657c0-3.27-.704-5.783-4.526-5.783-1.835 0-3.065 1.007-3.568 1.96h-.051v-1.66H9.237zm-6.142 0H6.87v12.139H3.095z"></path>
                        </svg>
                      </svg>
                    </HashLink>
                  </li>
                  <li className="ml-4">
                    <HashLink
                      to="https://m.facebook.com/Catech.Com.eg/about/"
                      className="flex justify-center items-center text-blue-900 hover:text-gray-500 bg-white hover:bg-white-100 rounded-full shadow transition duration-150 ease-in-out"
                      aria-label="LinkedIn"
                    >
                      <svg
                        className="w-8 h-8 fill-current"
                        viewBox="0 0 32 32"
                        xmlns="http://www.w3.org/2000/svg"
                      >
                        <path d="M14.023 24L14 17h-3v-3h3v-2c0-2.7 1.672-4 4.08-4 1.153 0 2.144.086 2.433.124v2.821h-1.67c-1.31 0-1.563.623-1.563 1.536V14H21l-1 3h-2.72v7h-3.257z" />
                      </svg>
                    </HashLink>
                  </li>
                </ul>
              </div>
            </div>
          </div>

          <div className="flex flex-wrap items-center md:justify-between justify-center mx-auto px-4">
            <div className="w-full md:w-4/12 px-4 mx-auto text-center py-2">
              <div className="text-sm text-gray-200 font-semibold py-1">
                Copyright &copy; {new Date().getFullYear()}
                {"  "}
                <HashLink to="#" className=" hover:text-gray-900">
                  CATECH Inc
                </HashLink>
                . All rights reserved.
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
};
export default Footer;
